import { ApolloProvider } from '@apollo/client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { CurrentUserQuery } from '../graphql/auth/currentUserQuery';
import { RedeemForgotPasswordTokenMutation } from '../graphql/auth/forgotPassword/redeemForgotPasswordTokenMutation';
import { LoginWithPasswordMutation } from '../graphql/auth/loginWithPasswordMutation';
import { RegisterRiderMutation } from '../graphql/auth/registerRiderMutation';
import { PasswordChangeType, UserType } from '../utils/utilTypes';
import { createApolloClient } from './apollo';
import { clearToken, getToken, saveToken } from '../utils/utilsToken';

type LoginResponseType = {
  authenticateUserWithPassword: {
    item: UserType;
    __typename: string;
    sessionToken: string;
  };
};

export const AuthContext = createContext<{
  getCurrentUser: () => Promise<UserType | null>;
  currentUser: UserType | null;
  login: (user: UserType) => void;
  logout: () => void;
  signUp: (user: UserType) => void;
  changePassword: (payload: PasswordChangeType) => void;
}>({
  getCurrentUser: () => Promise.resolve(null),
  currentUser: null,
  logout: () => {},
  login: () => {},
  signUp: () => {},
  changePassword: () => {},
});

let apolloClient = createApolloClient();

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </AuthContext.Provider>
  );
};

// this gets imported in components where we need auth
export const useAuth = () => {
  return useContext<{
    getCurrentUser: () => Promise<UserType | null>;
    currentUser: UserType | null;
    login: (user: UserType) => void;
    logout: () => void;
    signUp: (user: UserType) => void;
    changePassword: (payload: PasswordChangeType) => void;
  }>(AuthContext);
};

function useProvideAuth() {
  const [currentUser, setCurrentUser] = useState<UserType | null>(null);

  const login = async ({
    email,
    password,
  }: UserType): Promise<LoginResponseType> => {
    const { data } = await apolloClient.mutate({
      mutation: LoginWithPasswordMutation,
      variables: {
        email: email.toLowerCase(),
        password,
      },
    });

    if (
      data?.authenticateUserWithPassword?.__typename ===
      'UserAuthenticationWithPasswordSuccess'
    ) {
      const token = data?.authenticateUserWithPassword.sessionToken;
      saveToken(token);
      setCurrentUser(data?.authenticateUserWithPassword?.item);
    }

    return data?.authenticateUserWithPassword;
  };

  const signUp = async ({
    email,
    password,
    name,
    phoneNumber,
    isTermsAccepted,
    isOfLegalAge,
    companyId,
  }: UserType) => {
    const { data } = await apolloClient.mutate({
      mutation: RegisterRiderMutation,
      variables: {
        email: email.toLowerCase(),
        password,
        name,
        phoneNumber,
        isTermsAccepted,
        isOfLegalAge,
        companyId,
      },
    });
  };

  const logout = () => {
    setCurrentUser(null);
    clearToken();
  };

  const changePassword = async ({
    email,
    password,
    token,
  }: PasswordChangeType) => {
    const { data } = await apolloClient.mutate({
      mutation: RedeemForgotPasswordTokenMutation,
      variables: {
        email,
        password,
        token,
      },
    });

    if (data?.redeemUserPasswordResetToken?.code === 'FAILURE') {
      return { error: data?.redeemUserPasswordResetToken?.message };
    }

    return { error: null, data: data?.redeemUserPasswordResetToken };
  };

  const getCurrentUser = async () => {
    const { data } = await apolloClient.query({
      query: CurrentUserQuery,
      fetchPolicy: 'network-only',
    });

    const user = data?.authenticatedItem || null;

    setCurrentUser(user);
    return user;
  };

  return {
    getCurrentUser,
    currentUser,
    login,
    logout,
    signUp,
    changePassword,
  };
}
